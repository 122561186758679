import * as React from "react"
import Layout from "../components/Layout"
import "../assets/css/gleisdorf.css"
import "animate.css"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Navigation from "../components/Navigation"

const Gleisdorf = ({ data }) => {
  const { information, exclamation, gleisdorf, male, female } = data
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Dr. Dieter Krametter - Neurologe mit allen Kassen - Raum Gleisdorf u
          Raabtal - Kassenarzt
        </title>
      </Helmet>
      <div className="gleisdorfHeader">Raum Gleisdorf und Raabtal</div>
      <div className="gleisdorfHeaderText">
        <GatsbyImage
          image={getImage(information)}
          title={information.title}
          alt="Information"
        />
        <p>
          Für Patienten im Raum Gleisdorf steht unsere Praxis, gut erreichbar
          über die B64, als fachärztliche neurologische Ordination, mit
          Verträgen mit allen Krankenkassen gerne zur Verfügung.
        </p>
      </div>

      <div className="gleisdorfHeaderText">
        <GatsbyImage
          image={getImage(exclamation)}
          title={exclamation.title}
          alt="Information"
        />
        <p>
          Hier finden Sie Informationen über unsere Ordinationszeiten und
          allgemeine Infos über die{" "}
          <Link to="/">neurologische Praxis Dr. Krametter</Link>
        </p>
      </div>
      <div className="gleisdorfImage">
        <GatsbyImage
          image={getImage(gleisdorf)}
          title={gleisdorf.title}
          alt="Gleisdorf"
        />
      </div>

      <div className="gleisdorfBannerContainer">
        <div className="gleisdorfBannerContainerWrapper">
          <div className="gleisdorfBannerText">
            Falls Sie eine wohnortnahe wahlärztliche Ordination
            (Neurologin/Neurologe) in Gleisdorf suchen, empfehle ich folgende
            Kolleginnen/Kollegen:
          </div>
        </div>

        <div className="gleisdorfBannerContainerWrapper">
          <div className="gleisdorfBannerDiv">
            <table className="gleisdorfTable">
              <tr>
                <td className="tableImage">
                  <GatsbyImage
                    image={getImage(female)}
                    title={female.title}
                    alt="Information"
                  />
                </td>
                <td className="tableText">
                  <p>
                    <b>Dr. Susanne Schmidegg</b>
                  </p>
                  <p>Fachärztin für Neurologie</p>
                  <p>Businesspark 2, 8200 Gleisdorf</p>
                  <p> Tel: 0650 99 23 558</p>
                </td>
              </tr>
            </table>

            <table className="gleisdorfTable">
              <tr>
                <td className="tableImage">
                  <GatsbyImage
                    image={getImage(male)}
                    title={male.title}
                    alt="Information"
                  />
                </td>
                <td className="tableText">
                  <p>
                    <b>Dr. Stephan Pölzl</b>
                  </p>
                  <p>Facharzt für Neurologie</p>
                  <p>Franz Josef Straße 17, 8200 Gleisdorf</p>
                  <p>Tel: 0660 267 4867</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <Navigation> </Navigation>
    </Layout>
  )
}

export default Gleisdorf

export const pageQuery = graphql`
  query GleisdorfQuery {
    information: contentfulAsset(
      contentful_id: { eq: "1aodisFRq5NhCZBAoCihDA" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    exclamation: contentfulAsset(
      contentful_id: { eq: "bQSbakr2yqQNqCCex70DY" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    gleisdorf: contentfulAsset(contentful_id: { eq: "M12HtVwgJ3GR3a8BSca8l" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    male: contentfulAsset(contentful_id: { eq: "18zIjJUS3wapetFgvbQibc" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    female: contentfulAsset(contentful_id: { eq: "2FtyqcDP2YHQQn294IOAQh" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
